import React from "react";
import type { NextPage } from "next";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	SectionServices,
	SectionStudio,
	IntroductionSection,
	SectionContact,
} from "~/components";
import { ContactField } from "~/components/ContactForm";
import { OgTags } from "~/components/Ogtags";
import ContactStore from "~/stores/ContactStore";
import { strings } from "~/resources";

import { homeOgTags } from "~/resources/PageOgTags";
import { useRouter } from "next/router";

const Home: NextPage = () => {

	const contactStore = useLocalObservable(() => new ContactStore());

	const onChangeInput = (field: ContactField, value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		contactStore.form.field(field).onChange(value);
	};

	const router = useRouter();

	const goToHome = () => {
		router.push("/");
	};

	const goToTaxStudio = () => {
		router.push("/taxStudio");
	};

	const introductionSection = strings.components.introductionSection;

	return (
		<>
			<OgTags {...homeOgTags} />
			<IntroductionSection
				srcImage="/sectionInitial.jpg"
				title={introductionSection.title}
				description={introductionSection.description}
				list={introductionSection.list}
			/>
			<SectionServices />
			<SectionStudio onClick={goToTaxStudio}/>
			<SectionContact
				contact={contactStore.form.getValues()}
				onHandleChange={onChangeInput}
				onSubmit={() => contactStore.createContact(goToHome)}
				loading={contactStore.loader.isLoading}
			/>
		</>
	);
};

export default observer(Home);
