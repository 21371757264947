import React from "react";
import Head from "next/head";
import strings from "~/resources/strings";

export interface IImage {
	url: string;
	type: "image/png";
	width: number;
	height: number;
}

export interface IProps {
	type: "blog" | "website" | "author";
	title: string;
	description: string;
	themeColor: string;
	keywords: string[];
	url: string | null;
	twitter: {
		type: "summary" | "summary_large_image";
		title?: string;
		description?: string;
		image?: IImage;
	};
	canonicalUrl?: string;
	image?: IImage;
}

export const OgTags: React.FC<IProps> = (props: IProps) => {
	const componentStrings = strings.ogTags;
	const {
		description,
		keywords,
		themeColor,
		title,
		twitter,
		type,
		url,
		image,
	} = props;

	return (
		<Head>
			{/* MARK: Brand */}
			<meta key="theme-color" name="theme-color" content={themeColor} />

			{/* MARK: General */}
			<title>{title}</title>
			<meta name="title" content={title} />
			<meta key="description" name="description" content={description} />
			<meta key="keywords" name="keywords" content={keywords.join(",")} />

			{twitter && (
				<>
					{/* MARK: Twitter */}
					<meta key="twitter:card" name="twitter:card" content={twitter.type} />
					<meta key="twitter:title" name="twitter:title" content={twitter.title} />
					<meta key="twitter:description" name="twitter:description" content={twitter.description} />
					{twitter.image || image && (
						<meta key="twitter:image" name="twitter:image" content={(twitter.image || image).url} />
					)}
				</>
			)}

			{/* MARK: OG */}
			<meta key="og:type" property="og:type" content={type} />
			<meta key="og:site_name" property="og:site_name" content={componentStrings.name} />
			<meta key="og:title" property="og:title" content={title} />
			<meta key="og:description" property="og:description" content={description} />
			{url && (
				<meta key="og:url" property="og:url" content={url} />
			)}
			{image && (
				<>
					<meta key="og:image" property="og:image" content={image.url} />
					<meta key="og:image:type" property="og:image:type" content={image.type} />
					<meta key="og:image:width" property="og:image:width" content={`${image.width}`} />
					<meta key="og:image:height" property="og:image:height" content={`${image.height}`} />
				</>
			)}
		</Head>
	);
};
